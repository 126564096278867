

.fareGroup_white-OTgji {
	border-radius: 4px;
	border-top: 8px solid transparent;
}

.content_wrapper-2RDVj .fareGroup_white-OTgji[data-fareId='721'],
.content_wrapper-2RDVj .fareGroup_white-OTgji[data-fareId='722'],
.content_wrapper-2RDVj .fareGroup_white-OTgji[data-fareId='723'],
.content_wrapper-2RDVj .fareGroup_white-OTgji.fareGroup_recommended-1wHHi {
	border-color: var(--brand-1-0);
}

.content_wrapper-2RDVj .fareGroup_white-OTgji[data-fareId='724'] {
	border-color: var(--brand-3-0);
}

.fareGroup_white-OTgji svg.recommendedBadge_badge-X8qWq {
	color: var(--success-icon);
}

.slider_allOnce-2Toau .fareGroup_white-OTgji {
	border-color: var(--recommend-icon);
}

.keyFeature__value-lRF9t {
	text-align: center;
}

.fareGroup_white-OTgji .title-2eoTV {
	display: flex;
	align-items: center;
}

.fareGroup_white-OTgji .option__title-2FVfm {
	white-space: normal;
	white-space: initial;
}

.fareGroup_white-OTgji .keyFeature-2oVPM:not(.keyFeature_paid-2_68q):not(.keyFeature_unavailable-1SPWx) {
	background: var(--brand-1-0);
}

.fareGroup_white-OTgji .options-1dCj5 {
	padding-top: 7px;
}

.fareGroup_white-OTgji .option__value-2Eugw,
.fareGroup_white-OTgji .option_unavailable-31Kvx .option__value-2Eugw {
	color: var(--text-pale);
}

.fareGroup_white-OTgji .option_free-xRGM0 .option__value-2Eugw {
	color: var(--success-icon);
}

.fareGroup_white-OTgji .option__icon-2UmJ7,
.fareGroup_white-OTgji .option__title-2FVfm,
.fareGroup_white-OTgji .option_unavailable-31Kvx .option__icon-2UmJ7,
.fareGroup_white-OTgji .option_unavailable-31Kvx .option__title-2FVfm {
	color: var(--text-mid);
}

.fareGroup_white-OTgji.fareGroup_Business-1RIba .keyFeature_paid-2_68q,
.fareGroup_white-OTgji .keyFeature_unavailable-1SPWx {
	background: #f0f0f0;
}

/* stylelint-disable */
.fareGroup_white-OTgji.fareGroup_Business-1RIba .keyFeature_paid-2_68q .keyFeature__icon-1ocHa,
.fareGroup_white-OTgji.fareGroup_Business-1RIba .keyFeature_paid-2_68q .keyFeature__value-lRF9t,
.fareGroup_white-OTgji .keyFeature_unavailable-1SPWx .keyFeature__icon-1ocHa,
.fareGroup_white-OTgji .keyFeature_unavailable-1SPWx .keyFeature__value-lRF9t {
	color: var(--gray30);
}
/* stylelint-enable */
.content_wrapper-2RDVj .fareGroup_white-OTgji {
	border-radius: 4px;
	border-top: 8px solid transparent;
}

button.button-2Uaea {
	margin-top: auto;

	border-radius: 4px;

	font-size: 16px;
	font-weight: 500;
}

button.button-2Uaea,
button.button-2Uaea:hover {
	padding: 12px;
}

button.button-2Uaea .price-2gjU7 {
	font-size: 18px;
	font-weight: 600;
}

.recommendedBadge_badge-X8qWq {
	top: -24px;
	right: 25px;

	transform: scale(0.83);
}

.info-1S-CD {
	position: static;

	display: none;
}

.upgradeOptionWrapper-1R4hr {
	display: none;

	order: 2;
	margin-top: 12px;
}

.upgradeOffer-bPWKM {
	padding: 16px;

	border-radius: 2px;

	color: var(--brand-1-0);

	background: var(--bg-fill);
}

.upgradeOffer__link-3FWoY {
	font-weight: 600;
	font-size: 14px;
}

.upgradeOffer__link-3FWoY svg path {
	fill: var(--brand-1-0);
}
